.auth {
    padding-top: 75px;
}

.auth-container {
    outline: 1px solid #dbdad5;
    margin-left:auto;
    margin-right: auto;
    border-radius: 25px;
    height: 600px;
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    object-fit: contain;
}

.logo {
    color: #400d75;
    justify-self: start;
    margin-top: 40px;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
}

.input-areas {
    padding-top: 40px;
    padding-left: 80px;
    align-items: center;
}

.signup-btn {
    outline: black;
}

.auth-input {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: 1px solid #6f6f6f;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
}

.whitespace {
    height: 30px;
}

.whitespace2 {
    height: 82px;
}