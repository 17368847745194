
:root {
  --primary: #400d75;
}

  
  .auth-btn {
    background-color: #400d75;
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
  }
  
  .authbtn--primary {
    
    background-color: #400d75;
    color: #fff;
    border: 1px solid var(--primary);
  }
  
  .authbtn--outline {
    background-color: #400d75;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
  }
  
  .authbtn--medium {
    padding: 8px 20px;
    font-size: 18px;
  }
  
  .authbtn--large {
    padding: 12px 26px;
    font-size: 20px;
  }
  
  .authbtn--large:hover,
  .authbtn--medium:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #242424;
    transition: 250ms;
  }