.balance-container {
    outline: 1px solid #dbdad5;
    margin-left:auto;
    margin-right: auto;
    border-radius: 25px;
    height: 250px;
    width: 400px;
    display: flex;
    align-items: center;
    object-fit: contain;
}

.balance-title {
    font-size: x-large;
    margin-left: 30px;
    margin-top: -10px;
    width: 100%;
}

.balance {
    margin-left: 30px;
    margin-top: 10px;
    font-size: 45px;
    width: 100%;
}

.send-btn {
    margin-left: 30px;
    margin-top: 40px;
    width: 100%;
}