.tipjar-container {
    outline: 1px solid #dbdad5;
    margin-left:auto;
    margin-right: auto;
    border-radius: 25px;
    height: 500px;
    width: 400px;
    display: flex;
    align-items: center;
    object-fit: contain;
}

.jar {
    margin-left: auto;
    margin-right: auto;
    margin-top: -30px;
    display: inline-block;
    align-items: center;
}

.tip-title {
    font-size: x-large;
}

.tip-qr {
    background-image: url('../../public/images/frame.png');
    height: 300px;
    width: 300px;

}

.tip-link {
    width: 100%;
    text-align: center;
    font-size: xx-large;
}
