.action-container {
    outline: 1px solid #dbdad5;
    margin-left:auto;
    margin-right: auto;
    border-radius: 25px;
    height: 50px;
    width: 400px;
    display: flex;
    align-items: center;
    object-fit: contain;
}

.action {
    margin-left: 30px;
    font-size: larger;
}

.link {
    text-decoration: none;
}