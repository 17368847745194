.sql-terminal-container {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Align items to the start of the container */
    height: 100vh; /* Use the full height of the viewport */
    padding: 40px; /* Increase padding to add space from the top */
  }
  
  .sql-terminal {
    background-color: #1e1e1e;
    color: #c5c5c5;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 90%; /* Increase width to cover most of the screen */
    max-width: 95%; /* Increase max-width to allow more space */
    height: 80vh; /* Set terminal height to cover most of the viewport height */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Space between textarea and button */
  }
  
  .sql-terminal textarea {
    width: 100%; /* Adjust width to fill container */
    background-color: #2e2e2e;
    color: #9df;
    border: 1px solid #555;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    resize: none; /* Disable resizing */
    flex-grow: 1; /* Allow textarea to grow and fill available space */
    margin: 0 0 20px 0; /* Ensure margin between textarea and button */
  }
  
  .sql-terminal button {
    width: 100%; /* Button width fills the container */
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 15px 20px; /* Increase padding for better visibility */
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.2s;
    align-self: flex-end; /* Align button to the bottom */
  }
  
  .sql-terminal button:hover {
    background-color: #0056b3;
  }